import cx from 'classnames'

import { Icon } from '/features/buildingBlocks/Icon'

import styles from './Button.css'

export function ButtonDarkGreen({ type, onClick, disabled, tracking, dataX, name, children, icon = undefined, layoutClassName = undefined }) {
  return <ButtonBase className={cx(styles.componentDarkGreen, layoutClassName)} {...{ type, onClick, disabled, tracking, dataX, name, children, icon }} />
}

export function ButtonLinkGreen({ href, dataX, children, target, icon = undefined, layoutClassName = undefined }) {
  return <ButtonLinkBase className={cx(styles.componentLinkGreen, layoutClassName)} {...{ href, dataX, icon, children, target }} />
}

export function ButtonLinkDarkGreen({ href, dataX, children, target, icon = undefined, layoutClassName = undefined }) {
  return <ButtonLinkBase className={cx(styles.componentLinkDarkGreen, layoutClassName)} {...{ href, dataX, icon, children, target }} />
}

function ButtonBase({
  onClick,
  dataX,
  disabled,
  icon,
  children,
  className,
  type,
  name = undefined
}) {
  return (
    <button data-x={dataX} data-name={name} className={cx(styles.componentBase, icon && styles.hasIcon, className)} {...{ type, onClick, disabled }}>
      <span className={styles.label}>{children}</span>
      {icon && <Icon {...{ icon }} />}
    </button>
  )
}

function ButtonLinkBase({
  href,
  dataX,
  children,
  className,
  target = '_self',
  icon = undefined
}) {
  const rel = target === '_blank' ? 'noopener noreferrer' : null

  return (
    <a data-x={dataX} className={cx(styles.componentLinkBase, icon && styles.hasIcon, className)} {...{ href, target, rel }}>
      <span className={styles.label}>{children}</span>
      {icon && <Icon layoutClassName={styles.iconLayout} {...{ icon }} />}
    </a>
  )
}
