import { Icon } from '/features/buildingBlocks/Icon'

import styles from './Logo.css'

import logo from '/images/logo.raw.svg'

export function Logo({ href, layoutClassName = undefined }) {
  return (
    <a className={cx(styles.component, layoutClassName)} {...{ href }}>
      <Icon icon={logo} layoutClassName={styles.iconLayout} />
    </a>
  )
}
