const MenuContext = React.createContext(null)

export function MenuContextProvider({ children }) {
  const [id, setId] = React.useState(undefined)
  const [activeSubmenu, setActiveSubmenu] = React.useState({ submenuHeight: 0, submenuOffset: 0 })
  const [activeMobileMenu, setActiveMobileMenu] = React.useState(false)
  const [desktopSearchIsActive, setDesktopSearchIsActive] = React.useState(false)

  return (
    <MenuContext.Provider
      value={{
        id,
        activeSubmenu,
        onActiveSubmenuChange,
        activeMobileMenu,
        onActiveMobileMenuChange,
        desktopSearchIsActive,
        onDesktopSearchIsActiveChange
      }}
      {...{ children }}
    />
  )

  function onActiveSubmenuChange({ source = undefined, id, submenuHeight, submenuOffset }) {
    setActiveSubmenu({ submenuHeight, submenuOffset })
    setId(id)
  }

  function onActiveMobileMenuChange() {
    setActiveMobileMenu(!activeMobileMenu)
    setActiveSubmenu(prevState => ({ ...prevState, active: false }))
  }

  function onDesktopSearchIsActiveChange() {
    setDesktopSearchIsActive(!desktopSearchIsActive)
  }
}

export function useMenuContext() {
  return React.useContext(MenuContext)
}
