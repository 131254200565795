import { useFloating, useDismiss, useInteractions, autoUpdate, shift, useHover, useFocus, useRole } from '@floating-ui/react'
import { useI18n } from '/machinery/I18n'

import { useMenuContext } from '/features/pageOnly/menu/MenuContext'
import { Logo } from '/features/pageOnly/menu/Logo'
import { TicketButton } from '/features/pageOnly/menu/TicketButton'
import { Icon } from '/features/buildingBlocks/Icon'

import styles from './MenuDesktop.css'

import searchIcon from '/images/search.raw.svg'
import globeIcon from '/images/globe.raw.svg'
import closeIcon from '/images/mark.raw.svg'

export function MenuDesktop({
  menuItems,
  languageItems,
  homeLink,
  ticketUrl,
  ticketButtonTheme,
  hideTicketButton,
  search,
  layoutClassName = undefined
}) {
  const i18n = useI18n()

  return (
    <div className={cx(styles.component, layoutClassName)}>
      <Logo href={homeLink} layoutClassName={styles.logoLayout} />
      <div className={styles.menuContainer}>
        <Menu layoutClassName={styles.menuLayout} {...{ menuItems, languageItems, search }} />
        {!hideTicketButton && <TicketButton href={ticketUrl} label={i18n('tickets')} layoutClassName={styles.ticketButtonLayout} {...{ ticketButtonTheme }} />}
      </div>
      <Backdrop layoutClassName={styles.backdropLayout} />
    </div>
  )
}

function Backdrop({ layoutClassName = undefined }) {
  const { id, activeSubmenu } = useMenuContext()
  const { submenuHeight } = activeSubmenu

  return (
    <span
      style={{ '--submenu-height': `${submenuHeight}px` }}
      className={cx(styles.componentBackdrop, id !== null && styles.isActive, layoutClassName)}
    />
  )
}

function Menu({ menuItems, languageItems, search, layoutClassName = undefined }) {
  const searchInputRef = React.useRef(null)
  const currentLanguage = languageItems.find(item => item.isCurrent)?.slug

  return (
    <div className={cx(styles.componentMenu, layoutClassName)}>
      {Boolean(menuItems?.length) && (
        <ul className={styles.menuList}>
          {menuItems.map(({ label, submenu, isCurrent }, i) => (
            <MenuItem key={i} id={`${label}_${i}`} {...{ label, submenu, isCurrent }} />
          ))}
        </ul>
      )}

      <SearchFormRef ref={searchInputRef} layoutClassName={styles.searchFormLayout} {...{ search }} />
      <SearchButton {...{ searchInputRef }} />

      {Boolean(languageItems.length) && (
        <MenuItemWithDropdown
          id='languageSwitch'
          icon={globeIcon}
          value={currentLanguage}
          submenu={languageItems}
        />
      )}
    </div>
  )
}

const SearchFormRef = React.forwardRef(SearchForm)

function SearchForm({ search, layoutClassName = undefined }, ref) {
  const i18n = useI18n()
  const { action, query } = search
  const { desktopSearchIsActive } = useMenuContext()

  return (
    <form
      role="search"
      method="get"
      className={cx(
        styles.componentSearchForm,
        desktopSearchIsActive && styles.isActive,
        layoutClassName
      )}
      {...{ action }}
    >
      <div className={cx(styles.searchInputContainer, desktopSearchIsActive && styles.isActive)}>
        <input type='text' name='s' placeholder={i18n('zoeken')} defaultValue={query} className={styles.searchInput} {...{ ref }} />
        <button type='submit' aria-label={i18n('zoeken')} className={styles.submitSearchButton}>
          <Icon icon={searchIcon} />
        </button>
      </div>
    </form>
  )
}

function SearchButton({ searchInputRef }) {
  const i18n = useI18n()
  const { desktopSearchIsActive, onDesktopSearchIsActiveChange } = useMenuContext()

  return (
    <button onClick={handleClick} data-x='click-to-open-searchbar' className={cx(styles.componentSearchButton, desktopSearchIsActive && styles.isActive)}>
      <span className={cx(styles.searchButtonContainer, desktopSearchIsActive && styles.isActive)}>
        <Icon icon={searchIcon} layoutClassName={styles.iconLayout} />
        <span>{i18n('zoeken')}</span>
      </span>
      <span className={cx(styles.closeButtonContainer, desktopSearchIsActive && styles.isActive)}>
        <Icon icon={closeIcon} layoutClassName={styles.iconLayout} />
      </span>
    </button>
  )

  function handleClick() {
    onDesktopSearchIsActiveChange()
    searchInputRef.current?.focus()
  }
}

function MenuItem({ id, label, submenu, isCurrent }) {
  return (
    <li className={styles.componentMenuItem}>
      {submenu?.length
        ? <MenuItemWithDropdown {...{ id, label, submenu, isCurrent }} />
        : <MenuItemLabel {...{ label, isCurrent }} />
      }
    </li>
  )
}

function MenuItemWithDropdown({ id, submenu, label = undefined, icon = undefined, value = undefined, isCurrent: mainItemIsCurrent = undefined }) {
  const { id: activeId, activeSubmenu } = useMenuContext()
  const { getReferenceProps, getFloatingProps, style } = useFloatingProps({ id })

  const thisSubmenuIsActive = id === activeId
  const isCurrent = mainItemIsCurrent || submenu.some(x => x.isCurrent === true)
  const { submenuOffset } = activeSubmenu

  return (
    <div className={cx(styles.componentMenuItemWithDropdown, thisSubmenuIsActive && styles.thisSubmenuIsActive)} {...getReferenceProps()}>
      {(label || value) && <DropdownLabel {...{ label, icon, value, isCurrent }} />}

      <ul
        style={{ '--offset-submenu': `${submenuOffset}px`, ...style }}
        className={cx(styles.submenu, thisSubmenuIsActive && styles.thisSubmenuIsActive)}
        {...getFloatingProps()}
      >
        {submenu.slice(0, 12).map(({ label, link, isCurrent, slug }, i) =>
          <SubmenuLink key={i} {...{ label, link, isCurrent, slug }} />
        )}
      </ul>
    </div>
  )
}

function DropdownLabel({ label, icon, value, isCurrent }) {
  return (
    <span className={styles.componentDropdownLabel}>
      <MenuItemLabel {...{ label, icon, value, isCurrent }} />
    </span>
  )
}

function MenuItemLabel({ label, isCurrent, icon = undefined, value = undefined }) {
  return (
    <span className={cx( styles.componentMenuItemLabel, value && styles.hasValue, (isCurrent && !value) && styles.isCurrent )}>
      {icon && <Icon layoutClassName={styles.iconLayout} {...{ icon }} />}
      {label ?? value}
    </span>
  )
}

function SubmenuLink({ label, link, isCurrent, slug }) {
  const hebrewLanguage = slug === 'he'

  return (
    <li>
      <a
        href={link}
        data-x='link-in-submenu'
        className={cx(styles.componentSubmenuLink, hebrewLanguage && styles.hebrew, isCurrent && styles.isCurrent)}
      >
        <span>{label}</span>
      </a>
    </li>
  )
}

function useFloatingProps({ id }) {
  const { id: activeId, activeMobileMenu, onActiveSubmenuChange } = useMenuContext()

  const { y, strategy, refs, context, middlewareData } = useFloating({
    open: activeId === id,
    onOpenChange: (x, _, reason) => {
      (!x && reason !== 'focus') || reason === 'escape-key' || reason === 'outside-press'
        ? handleDismiss()
        : handleActiveSubmenuChange(x)
    },
    whileElementsMounted: autoUpdate,
    placement: 'bottom-start',
    middleware: [
      dimensions(),
      position(),
      shift()
    ]
  })

  const style = { position: strategy, top: y ?? 0 }

  const dismiss = useDismiss(context, { referencePress: false, outsidePress: true })
  const focus = useFocus(context)
  const hover = useHover(context)
  const role = useRole(context, { role: 'menu' })

  const { getReferenceProps, getFloatingProps } = useInteractions([
    dismiss, focus, hover, role
  ])

  function handleDismiss() {
    if (!activeMobileMenu) onActiveSubmenuChange({
      id: null,
      source: 'desktop',
      submenuHeight: 0,
      submenuOffset: 0
    })
  }

  function handleActiveSubmenuChange(x) {
    if (x) onActiveSubmenuChange({
      id,
      source: 'desktop',
      submenuHeight: middlewareData.dimensions?.height,
      submenuOffset: middlewareData.position?.x
    })
  }

  return {
    getReferenceProps: (x = {}) => getReferenceProps({ ...x, ref: refs.setReference }),
    getFloatingProps: (x = {}) => getFloatingProps({ ...x, ref: refs.setFloating }),
    style,
  }
}

function dimensions() {
  return {
    name: 'dimensions',
    fn: ({ rects }) => ({
      data: { height: rects.floating.height }
    }),
  }
}

function position() {
  return {
    name: 'position',
    fn: ({ rects }) => ({
      data: { x: rects.reference.x }
    }),
  }
}
