import { useI18n } from '/machinery/I18n'

import { useMenuContext } from '/features/pageOnly/menu/MenuContext'
import { Logo } from '/features/pageOnly/menu/Logo'
import { TicketButton } from '/features/pageOnly/menu/TicketButton'
import { Icon } from '/features/buildingBlocks/Icon'

import styles from './MenuMobile.css'

import searchIcon from '/images/search.raw.svg'
import chevronDownIcon from '/images/chevronDown.raw.svg'
import globeIcon from '/images/globe.raw.svg'

export function MenuMobile({
  menuItems,
  languageItems,
  homeLink,
  ticketUrl,
  ticketButtonTheme,
  hideTicketButton,
  layoutClassName = undefined
}) {
  return (
    <div className={cx(styles.component, layoutClassName)}>
      <Menu layoutClassName={styles.menuLayout} {...{ menuItems, languageItems }} />
      <NavBar layoutClassName={styles.navBarLayout} {...{ homeLink, ticketUrl, ticketButtonTheme, hideTicketButton }} />
    </div>
  )
}

function NavBar({ homeLink, ticketUrl, ticketButtonTheme, hideTicketButton, layoutClassName = undefined }) {
  const { activeMobileMenu: isActive } = useMenuContext()
  const i18n = useI18n()

  return (
    <div className={cx(styles.componentNavBar, isActive && styles.isActive, layoutClassName)}>
      <Hamburger layoutClassName={styles.hamburgerLayout} />
      <Logo href={homeLink} layoutClassName={styles.logoLayout} />
      {!hideTicketButton && <TicketButton href={ticketUrl} label={i18n('tickets')} layoutClassName={styles.ticketsButtonLayout} {...{ ticketButtonTheme }} />}
    </div>
  )
}

function Hamburger({ layoutClassName = undefined }) {
  const { activeMobileMenu: isActive, onActiveMobileMenuChange } = useMenuContext()
  const i18n = useI18n()

  return (
    <button onClick={onActiveMobileMenuChange} data-x='click-to-open-menu' className={cx(styles.componentHamburger, layoutClassName)}>
      <span className={cx(styles.hamburger, isActive && styles.isActive)} />
      <span className={cx(styles.hamburgerLabel, isActive && styles.isActive)}>{i18n('sluit')}</span>
    </button>
  )
}

function Menu({ menuItems, languageItems, layoutClassName = undefined }) {
  const i18n = useI18n()
  const { activeMobileMenu: isActive } = useMenuContext()
  const currentLanguage = languageItems.find(item => item.isCurrent)?.slug

  return (
    <div className={cx(styles.componentMenu, isActive && styles.isActive, layoutClassName)}>
      <header className={styles.menuHeader}>
        <Search layoutClassName={styles.searchLayout} />
      </header>

      {Boolean(menuItems?.length) && (
        <ul className={styles.menu}>
          {menuItems.map(({ label, link, submenu, isCurrent }, i) => (
            <MenuItem key={i} id={`${label}_${i}`} {...{ label, link, submenu, isCurrent }} />
          ))}
        </ul>
      )}

      {Boolean(languageItems?.length) && (
        <div className={styles.languageSwitchContainer}>
          <MenuItemWithDropdown
            id='languageSwitch'
            label={i18n('taal')}
            icon={globeIcon}
            value={currentLanguage}
            submenu={languageItems}
          />
        </div>
      )}
    </div>
  )
}

function Search({ layoutClassName = undefined }) {
  const i18n = useI18n()

  return (
    <form role='search' method='get' className={cx(styles.componentSearch, layoutClassName)}>
      <input type='text' name='search' placeholder={i18n('zoeken')} className={styles.searchInput} />
      <button type='submit' aria-label={i18n('zoeken')} className={styles.submitSearchButton}>
        <Icon icon={searchIcon} layoutClassName={styles.iconLayout} />
      </button>
    </form>
  )
}

function MenuItem({ id, label, link, submenu, isCurrent }) {
  return (
    <li className={styles.componentMenuItem}>
      {submenu?.length
        ? <MenuItemWithDropdown {...{ id, label, link, submenu, isCurrent }} />
        : <MenuItemLink {...{ label, link, isCurrent }} />
      }
    </li>
  )
}

function MenuItemWithDropdown({ id, submenu, label, onKeyDown = undefined, isCurrent = undefined, icon = undefined, value = undefined }) {
  const { id: activeId } = useMenuContext()

  const thisSubmenuIsActive = id === activeId

  return (
    <div className={cx(styles.componentMenuItemWithDropdown, thisSubmenuIsActive && styles.thisSubmenuIsActive)}>
      {label && (
        <SubmenuButton
          layoutClassName={styles.submenuButtonLayout}
          {...{ id, isCurrent, label, icon, value, thisSubmenuIsActive }}
        />
      )}

      <div className={cx(styles.submenuContainer, thisSubmenuIsActive && styles.thisSubmenuIsActive)}>
        <ul className={styles.submenu}>
          {submenu.map(({ label, link, isCurrent }, i) =>
            <SubmenuLink key={i} {...{ label, link, isCurrent }} />
          )}
        </ul>
      </div>
    </div>
  )
}

function SubmenuButton({ id, label, icon, value, isCurrent, thisSubmenuIsActive, layoutClassName = undefined }) {
  const { id: activeId, onActiveSubmenuChange } = useMenuContext()

  return (
    <button
      onClick={handleClick}
      data-x='click-to-open-submenu'
      className={cx(
        styles.componentSubmenuButton,
        thisSubmenuIsActive && styles.thisSubmenuIsActive,
        layoutClassName
      )}
    >
      <MenuItemLabel {...{ label, isCurrent }} />

      <div className={styles.statesContainer}>
        {icon && <Icon layoutClassName={styles.iconLayout} {...{ icon }} />}
        {value && <span className={styles.currentStateValue}>{value}</span>}
        <div className={cx(styles.iconContainer, thisSubmenuIsActive && styles.thisSubmenuIsActive)}>
          <Icon icon={chevronDownIcon} layoutClassName={styles.iconLayout} />
        </div>
      </div>
    </button>
  )

  function handleClick() {
    onActiveSubmenuChange({
      id: activeId !== id ? id : null,
      source: 'mobile',
    })
  }
}

function MenuItemLink({ label, link, isCurrent }) {
  return (
    <a href={link} data-x='link-in-menu' className={styles.componentMenuItemLink}>
      <MenuItemLabel {...{ label, isCurrent }} />
    </a>
  )
}

function MenuItemLabel({ label, isCurrent }) {
  return (
    <span className={cx(styles.componentMenuItemLabel, isCurrent && styles.isCurrent)}>
      {label}
    </span>
  )
}

function SubmenuLink({ label, link, isCurrent }) {
  return (
    <li>
      <a
        href={link}
        data-x='link-in-submenu'
        className={cx(styles.componentSubmenuLink, isCurrent && styles.isCurrent)}
      >
        <span>{label}</span>
      </a>
    </li>
  )
}
