import { FloatingOverlay as FloatingOverlayBase } from '@floating-ui/react'

import { MenuContextProvider, useMenuContext } from '/features/pageOnly/menu/MenuContext'
import { ContainerXxl } from '/features/buildingBlocks/Container'
import { MenuMobile } from '/features/pageOnly/menu/MenuMobile'
import { MenuDesktop } from '/features/pageOnly/menu/MenuDesktop'

import styles from './SiteHeader.css'

export function SiteHeader({ homeLink, ticketUrl, ticketButtonTheme, hideTicketButton, menuItems, languageItems, search }) {
  return (
    <header className={styles.component}>
      <MenuContextProvider>
        <Menu {...{ menuItems, languageItems, homeLink, ticketUrl, ticketButtonTheme, hideTicketButton, search }} />
      </MenuContextProvider>
    </header>
  )
}

function Menu({ menuItems, homeLink, languageItems, ticketUrl, ticketButtonTheme, hideTicketButton, search }) {
  const { activeMobileMenu } = useMenuContext()

  return (
    <FloatingOverlayBase lockScroll={activeMobileMenu} className={styles.componentMenu}>
      <ContainerXxl>
        <div className={styles.menuContainer}>
          <MenuMobile layoutClassName={styles.menuMobileLayout} {...{ menuItems, languageItems, homeLink, ticketUrl, ticketButtonTheme, hideTicketButton, search }} />
          <MenuDesktop layoutClassName={styles.menuDesktopLayout} {...{ menuItems, languageItems, homeLink, ticketUrl, ticketButtonTheme, hideTicketButton, search }} />
        </div>
      </ContainerXxl>
    </FloatingOverlayBase>
  )
}
