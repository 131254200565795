import { usePhpProps } from '/machinery/usePhpProps'
import { I18nContextProvider } from '/machinery/I18n'

import { SiteHeader } from './SiteHeader'

export default function SiteHeaderApp({ phpProps }) {
  const {
    search,
    menu_items: menuItems,
    language_items: languageItems,
    home_url: homeLink,
    ticket_url: ticketUrl,
    tickets_button_theme: ticketButtonTheme,
    hide_ticket_button: hideTicketButton,
    i18n
  } = usePhpProps(phpProps, {
    menu_items: [],
    language_items: [],
    home_url: '',
    ticket_url: '',
    ticket_button_theme: '',
    hide_ticket_button: false,
    search: { query: '', action: '' },
    i18n: {}
  })

  return (
    <I18nContextProvider value={i18n}>
      <SiteHeader {...{ menuItems, languageItems, homeLink, ticketUrl, ticketButtonTheme, hideTicketButton, search }} />
    </I18nContextProvider>
  )
}
