import { ButtonLinkGreen, ButtonLinkDarkGreen } from '/features/buildingBlocks/Button'

export function TicketButton({ href, label, ticketButtonTheme, layoutClassName = undefined }) {
  const props = { href, target: '_blank', tracking: 'tickets', dataX: 'link-to-tickets', layoutClassName }

  switch (ticketButtonTheme) {
    case 'dark': return <ButtonLinkDarkGreen {...props}>{label}</ButtonLinkDarkGreen>
    case 'green': return <ButtonLinkGreen {...props}>{label}</ButtonLinkGreen>
    default: return <ButtonLinkGreen {...props}>{label}</ButtonLinkGreen>
  }
}
